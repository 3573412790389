'use client';

import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { Journey } from 'utils/journey';

type OfferType = Journey | null;
const defaultOffer: OfferType = null;

const QuoteContext = createContext<[OfferType, Dispatch<SetStateAction<OfferType>>]>([
  defaultOffer,
  () => {},
]);

type Props = {
  children?: React.ReactNode;
};

export const QuoteProvider = ({ children }: Props) => {
  const [offer, setOffer] = useState<OfferType>(defaultOffer) as [
    OfferType,
    Dispatch<SetStateAction<OfferType>>,
  ];

  return (
    <QuoteContext.Provider value={[offer, setOffer]}>{children}</QuoteContext.Provider>
  );
};

export const useQuote = () => useContext(QuoteContext);
